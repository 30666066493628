<template>
    <div class="login-page">
      <div class="row">
          <span class="login-title">请填写以下注册信息</span>
          <span class="login-des">绑定您的手机号注册账号更便捷</span>
      </div>
      <div class="row mt-20 login-form">
        <div class="row login-form-item mt-10">
          <div class="form-label">姓名</div>
          <div class="form-input"><div class="lf-input"><input type="text" maxlength="11"  autocomplete="new-password" placeholder="请输入您的姓名" v-model="loginForm.username" /></div></div>
        </div>
        <!--<div class="row login-form-item mt-20">
          <div class="form-label">企业名称</div>
          <div class="form-input"><div class="lf-input"><input type="text" autocomplete="new-password" placeholder="请输入您的企业名称" v-model="loginForm.companyName" /></div></div>
        </div>-->
        <div class="row login-form-item mt-10">
          <div class="form-label">手机号</div>
          <div class="form-input"><div class="lf-input"><input type="text" maxlength="11"  autocomplete="new-password" placeholder="请输入您的手机号" v-model="loginForm.phone" /></div></div>
        </div>
        <div class="row login-form-item mt-20">
          <div class="form-label">验证码</div>
          <div class="form-input"><div class="lf-input"><input type="text" maxlength="4" autocomplete="new-password" placeholder="请输入您的验证码" v-model="loginForm.smsCode" /></div></div>
          <div class="login-mobile-code" :class="timeCutdown == 60 ? '' : 'getcode-ing'" @click="sengMsgCode">{{sendCode}}</div>
        </div>
        <div class="row login-form-item mt-20">
          <div class="form-label">登录密码</div>
          <div class="form-input"><div class="lf-input"><input type="password" autocomplete="new-password" placeholder="请输入您的新密码" v-model="loginForm.password" /></div></div>
        </div>
      </div>
      <div class="row mt-40">
        <div class="login-btn" @click="onSubmit">提交</div>
      </div>
    </div>
</template>

<script>
import { mobileRegister,getPhoneCode } from "@/api/user";
import { setToken } from "@/utils/auth";
import { checkPhone } from "@/utils/comm";
const reg_mobile = /^0?(13|14|15|16|17|18|19)[0-9]{9}$/;

export default {
  data() {
    return {
      sendCode:'获取验证码',
      isGetMCode:false,
      ableToSend: false,
      timeCutdown: 60,
      sendTimer: '',

      codeUrl: "",
      reform:1,
      loginForm:{
        username:"",
        companyName:'',
        phone: "",
        password: "",
        smsCode:'',
        code:null,
        uuid:null,
      },
      formre:{},
      themeVars:{}
    };
  },
  created() {
    this.resetForm();
  },
  mounted() {},
  computed: {
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    },
    'loginForm.phone'(){
      if (reg_mobile.test(this.loginForm.phone) && this.timeCutdown === 60) {
        this.ableToSend = true;
      }
    },
    userCode () {
      if (reg_mobile.test(this.loginForm.phone) && this.timeCutdown === 60) {
        this.ableToSend = true;
      }
      if (this.loginForm.code.length !== 4) {
        this.userCodeTips = '短信验证码格式有误'
      } else {
        this.userCodeTips = ''
      }
    },
    timeCutdown () {
      if (this.timeCutdown <= 0) {
        this.timeCutdown = 60
        this.sendCode = '获取验证码'
        clearInterval(this.sendTimer)
      }
    }
  },
  methods: {
    resetForm(){
      this.loginForm.username = '';
      this.loginForm.companyName = '';
      this.loginForm.phone = '';
      this.loginForm.password = '';
      this.loginForm.smsCode = '';
    },
    // 发送验证码
    sengMsgCode () {
      var self = this;
      if(!self.ableToSend){
        return false;
      }
      self.requestCode();
    },
    // 请求验证码
    requestCode () {
      if (this.loginForm.phone === '') {
        this.$toast.fail('手机号不能为空')
        return false
      }
      if (!reg_mobile.test(this.loginForm.phone)) {
        this.$toast.fail('手机号格式有误，请重新输入')
        return false
      }
      let self = this
      self.ableToSend = false;
      let params = {
        phone: self.loginForm.phone
      }
      this.isGetMCode = true;
      getPhoneCode(params).then(function (res) {
        if (res.code == 200) {
          self.timeCutdown = res.data ? res.data : 60
          self.ableToSend = false;
          self.sendTimer = setInterval(function () {
            if ( self.timeCutdown > 0) {
              self.timeCutdown--
              self.sendCode = self.timeCutdown + '秒后重新发送';
              self.ableToSendCompleted=true  
            }
            if(self.timeCutdown  == 0){
              self.ableToSend = true;
              self.ableToSendCompleted=false
            }
          }, 1000);
          self.$notify.success(res.msg)
        } else {
          self.$toast.fail(res.msg)
        }
      }).catch(() => {
      })
    },
    // 提交行为
    onSubmit() {
      // 发请求，获取到后台的token，保存到cookies里，获取当前登录的用户信息
      if(!this.loginForm.username){
        this.$toast.fail('姓名不能为空');
        return false;
      }
      /*if(!this.loginForm.companyName){
        this.$toast.fail('企业名称不能为空');
        return false;
      }*/
      if(!this.loginForm.phone){
        this.$toast.fail('手机号不能为空');
        return false;
      }else if(!checkPhone(this.loginForm.phone)){
        this.$toast.fail('手机号格式不正常');
        return false;
      }
      if(!this.loginForm.password){
        this.$toast.fail('密码不能为空');
        return false;
      }
      if(!this.loginForm.smsCode){
        this.$toast.fail('验证码不能为空');
        return false;
      }
      mobileRegister(this.loginForm).then((res)=> {
        if(res.code == 200){
          this.$toast.loading('注册成功');
          // setToken(res.token);
          this.$nextTick(()=>{
            // 路由跳转
            this.$router.push("/login");
          })
        }
      })
    },
  },
};
</script>
<style lang="less">
</style>