/*
 * @Description:用户登录登出api
 * @Author: Ronda
 * @Date: 2021-06-29 15:52:14
 * @LastEditors: lucl
 * @LastEditTime: 2021-07-01 22:08:04
 */
import {get, put, post, postJSON } from '@/utils/request';

// 登录api
export function login(data) {
    return postJSON('/login', data);
}
// 手机号登录
export function loginPhone(data) {
    return postJSON('/login/phone', data)
}
// 登出api
export function logout() {
    return post('/logout');
}

// 注册api
export function mobileRegister(data) {
    return postJSON('/mobile/register', data);
}
// 员工注册api
export function registerEmployee(data) {
    return post('/user/registerEmployee', data)
}
export function info(data) {
    return get('/system/user/profile', data)
}
// 获取验证码
export function getPhoneCode(data) {
    return get('/sms/code', data)
}

// 忘记密码
export function forgetUserPwd(data) {
    return postJSON('/mobile/user/resetPwd', data)
}
// 用户密码重置
export function updateUserPwd(data) {
    return put('/system/user/profile/updatePwd', data)
}

// 用户账户详情
export function accountGet(data) {
    return get('/system/company/accountGet', data)
}

// 用户账户修改
export function accountUpdate(data) {
    return postJSON('/system/company/accountUpdate', data)
}
// 用户收货地址
export function userAddressList() {
    return get('/system/recipient/list')
}

// 收件信息新增接口：
export function userAddressAdd(data) {
    return postJSON('/system/recipient/add', data)
}

// 收件信息详情接口：
export function userAddressDetail(id) {
    return get('/system/recipient/' + id)
}

// 收件信息编辑修改接口：
export function userAddressEdit(data) {
    return postJSON('/system/recipient/edit', data)
}